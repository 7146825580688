import * as React from "react"
import styled from "styled-components"

import Layout from "../components/layout"
import { Column, Row } from "@react-tiny-grid/core"
import { StaticImage } from "gatsby-plugin-image"

const Box = styled.div`
  background-color: #e5e5e540;
  padding: 20px;
  border-radius: 10px;
  height: 100%;
`

const Text = styled.p`
  font-size: 1.2rem;
  font-weight: 300;
`

const FounderBox = styled.div`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 25px;
  background-color: #153250;
  color: #fff;
  max-width: 400px;
  height: 100%;
  margin: 10px;
`

const FounderBoxHead = styled.div`
  display: flex;
  align-items: center;
`

const Founders = styled.div`
  display: flex;
  justify-content: space-around;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`

const AboutPage = () => (
  <Layout>
    <Row>
      <Column widths={[5]}>
        <Box>
          <h2>ABOUT US</h2> <br />
          <Text>
            We are a software boutique company that strives to design and
            develop innovative solutions that make your life easier and help you
            become more productive, supported by the best customer service.
          </Text>
        </Box>
      </Column>

      <Column widths={[7]}>
        <Box>
          <h2>Our PHILOSOPHY and MISSION</h2> <br />
          <Text>
            We use our experience in the field to identify gaps and create
            solutions that solve daily challenges and optimize your valuable
            time.
            <br /> <br />
            We leverage the latest technological advancements to build the next
            generation of products, increasing the quality while driving costs
            down for the customer.
          </Text>
        </Box>
      </Column>
    </Row>

    <Row>
      <Column widths={[10]} offsets={[1]}>
        <Box>
          <h2 className="text-center">Meet the Founders</h2> <br />
          <Text className="text-center">
            Behind our software’s success is the talent and passion of a diverse
            group of specialists.
          </Text>
          <br /> <br />
          <Founders>
            <FounderBox>
              <FounderBoxHead>
                <StaticImage
                  src="../images/riccardo.jpg"
                  loading="eager"
                  width={80}
                  formats={["auto", "webp", "avif"]}
                  alt="Product Image"
                />
                <div style={{ marginLeft: 20 }}>
                  <h4>Riccardo T.</h4>
                  <br />
                  <h5>Co-Founder &amp; CEO</h5>
                </div>
              </FounderBoxHead>
              <br />
              <p>
                Riccardo is a seasoned and passionate Security Expert, he
                possesses over 20 years of combined experience in Cyber-Physical
                Security Operations and software development with focus on
                Digital Forensics, Incident Response and OSINT.
              </p>
            </FounderBox>

            <FounderBox>
              <FounderBoxHead>
                <StaticImage
                  src="../images/silvia.jpg"
                  loading="eager"
                  width={80}
                  formats={["auto", "webp", "avif"]}
                  alt="Product Image"
                />
                <div style={{ marginLeft: 20 }}>
                  <h4>Silvia L.</h4>
                  <br />
                  <h5>Co-Founder &amp; COO</h5>
                </div>
              </FounderBoxHead>
              <br />
              <p>
                Silvia is a Senior Financial Expert as she has over 15 years of
                combined experience in Business Administration, Risk Management,
                Fraud Prevention with focus on Digital Innovation and Business
                Strategy.
              </p>
            </FounderBox>
          </Founders>
        </Box>
      </Column>
    </Row>
  </Layout>
)

export default AboutPage
